import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, Link } from "gatsby"

const NotFoundPage = ({ data }) => (
  <Layout>
    <SEO title="404: Not found" />
    <div id="main">
      <div style={{ background: "#FFF" }}>
        <a href="/">
          <img
            id="logo"
            src={data.file.publicURL}
            alt="Enosh Bible Camp Logo"
          />
        </a>
      </div>
      <div className="diagonal">
        <svg viewBox="0 0 100 10" preserveAspectRatio="none">
          <polygon points="100,0 0,10 0,0" style={{ fill: "#FFF" }} />
        </svg>
        <Link className="button--anchor" to="/">
          Return to Home
        </Link>
      </div>
      <div className="info">
        <p>404 Page Not Found</p>
        <p className="header--verse">
          "For the Son of man is come to seek and to save that which was lost."
        </p>
        <span className="header--verse">- Luke 19:10</span>
      </div>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    file(relativePath: { eq: "campenoshlogomini_web.svg" }) {
      publicURL
    }
  }
`

export default NotFoundPage
